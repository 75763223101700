import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql, Link, navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"
import { Modal } from "react-bootstrap"
import { Collapse } from "reactstrap"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import { Button } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import close_icon from "../images/closed_icon-blue.svg"
import debitForm from "../pdf/automatic-debt-authorization-form.pdf"
import useLiveControl from "../controlled/useLiveControl"
import ResendLoanDocsPopup from "../components/resendLoanDocsPopup"

// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  // browserHistory.push('/'+url);
  navigate(url)
}



const DownloadFile = url => {
  window.open(url, "_blank")
}

const HomeOwnerPage = ({ data }) => {
  const node = data.wpgraphql.page
  const [collapse, setCollapse] = useState(false)
  const [accLogin, togglePanel] = useState(false)
  const loginForm = () => {
    togglePanel(true)
  }

  const closeLoginForm = () => {
    togglePanel(false)
  }

  const toggle = e => {
    e.preventDefault()
    setCollapse(!collapse)
  }

  const [value, setValue] = useState("")
  const handleChange = event => {
    setValue(event.target.value)
  }

  const [open, setOpen] = useState(false)

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Home Improvement Lending"}
        description={
          "Start your home remodeling project with home improvement financing and loans through EnerBank USA. Get approved and get started today."
        }
      />
      <Helmet>
        <body className="page-homeowner" />
      </Helmet>
      {isStaging ? (
        <section 
        className="banner-area"
        id="homeowner-page-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "https://gbdevpress.enerbank.com/wp-content/uploads/2022/11/homeowner-hero.jpg" +
                          ")",
                      }}
                  >
                <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                      Refer contractors<br/> to Regions
                      </h1>
                    </div>
                  </div>
          </div>
          <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
              Refer contractors to Regions
            </h1>
          </div>
      </section>
        ) : (
          <div>
        {node.video_section.video.videoUrl && (
        <section
          className="banner-area editable-page-hero-banner"
          id="homeowner-page-banner-section"
          style={{
            backgroundImage:
              "url(" +
              "http://gbdevpress.enerbank.com/wp-content/uploads/2022/11/homeowner-hero.jpg" +
              ")",
          }}
        >
          <div className="background-holder">
            {node.top_banner.banner.bannerLinks && (
              <div className="container d-md-flex align-items-end px-0 pb-3 pb-md-4 pb-lg-5 position-absolute banner-btn-container homeowner-banner-btn-container">
                {node.top_banner.banner.bannerLinks.map((item, i) => {
                  if (i === 1) {
                    const links = item.links.url
                    return (
                      <div className="col-md-3" key={item.fieldGroupName + i}>
                        <div className="header-btn mb-2 mb-md-0">
                        <Button
                        variant=""
                        className="btn sendLoanDoc card-link"
                        onClick={() => setOpen(true)}
                      >
                        Resend<br/> Loan Docs
                      </Button>
                        </div>
                      </div>
                    )
                  } else if (i === 0) {
                    return (
                      <div className="col-md-3" key={item.fieldGroupName + i}>
                        <div className="header-btn mb-2 mb-md-0">
                          <Link
                            className="mr-auto btn"
                            to="#account-login"
                            onClick={loginForm}
                            dangerouslySetInnerHTML={{
                              __html: item.links.title,
                            }}
                          />
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className="col-md-3" key={item.fieldGroupName + i}>
                        <div className="header-btn mb-2 mb-md-0">
                          <Link
                            className="mr-auto"
                            to={item.links.url}
                            dangerouslySetInnerHTML={{
                              __html: item.links.title,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            )}
          </div>
        </section>
      )}
      </div>
    )}
      <section className="service-area section-gap pt-5" id="whychoosebank">
      {isStaging ? (
        <>
        <div className="container">
          <div className="col-md-12 mb-5">
            <h2 className="text-center">I'm interested in...</h2>
          </div>
          <div className="icon-cards row justify-content-center mb-5">
            <div className="col-lg-3">
              <Link className="card-link" to="#account-login" onClick={loginForm}>
                <div className="card">         
                    <div className="card-image make-payment">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/user-lock.png" className="" alt="Account login" width="60" />
                    </div>
                    <div className="card-body">
                        <h3>Account<br/>Login <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3">
            <Button
                  variant=""
                  className="btn sendLoanDoc"
                  onClick={() => setOpen(true)}
                >
                <div className="card">
                    <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/invoice.png" className="icon" alt="Resend loan docs" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Resend Loan Documents <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Button>
            </div>
            <div className="col-lg-3">
              <Link className="card-link" to="https://www.mysecuredock.com/Express/landing.aspx?ss=D1228B42-9BA0-4841-A209-98E6250A15B5">
                <div className="card">
                    <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/document-upload.png" className="icon" alt="Upload verification docs" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Upload Verification Documents <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
            <div className="col-lg-3">
              <Link className="card-link" to="/homeowner-referral/">
                <div className="card">
                    <div className="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/person-circle-arrows.png" className="icon" alt="Refer a contractor" width="70" />
                    </div>
                    <div className="card-body">
                        <h3>Refer a<br/> Contractor <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
          </div>
          <div className="row justify-content-center">
              <div className="col-md-12 header-text">
                <p className="has-normal-font-size py-2">
                Regions Home Improvement Financing is a specialized home improvement lender &#8208; that means we&apos;re experienced when it comes to providing loans for home improvement projects like yours. Since it&apos;s our specialty, we&apos;ve developed payment options that are tailored to home improvement needs. We&apos;ve been doing this for over two decades and have approved over a million home improvement loans.
                </p>

                <p className="has-normal-font-size py-2">
                You can get the entire project you want completed right now instead of waiting to save up the cash or completing it in stages. With a Regions Home Improvement Financing loan, your home is not used as collateral, there&apos;s no application fee, no closing or appraisal costs, and no prepayment penalties. Your contractor will offer you a choice of payment options, then you can use one of our fast and easy application methods to connect with our US based lending team to receive a credit decision within seconds. Approvals are valid for 120-180 days, giving you plenty of time to plan and complete your home improvement project.
                </p>
              </div>
            </div>
            </div>
            <div className="container-lg">
            {/* <div className="benefit-option mt-5">
              <ul>
                <li>
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="icon" alt="Quick and easy application process" width="80" /> 
                  <h4>Quick and Easy application process</h4>
                </li>
                <li>
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" /> 
                  <h4>U.S. Based Lending & Customer Service</h4>
                </li>
                <li>
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/calandar.png" className="icon" alt="120-180 Day Credit Commitment" /> 
                  <h4>120-180 Day Credit Commitment</h4>
                </li>
                <li>
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" /> 
                  <h4>FDIC<br/> Insured</h4>
                </li>
                <li>
                  <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" /> <h4>High Approval<br/> Rates</h4>
                </li>
              </ul>
            </div> */}
            <div className="icon-cards benefit-option row justify-content-center pt-5">
              <div className="col-md-2">
                  <div className="card justify-content-inherit">         
                      <div className="card-image">
                          <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="" alt="Quick and easy application process" width="60" />
                      </div>
                      <div className="card-body">
                          <h3>Quick and Easy application process</h3>
                      </div>
                  </div>
              </div>
              <div className="col-md-2">
                  <div className="card justify-content-inherit">
                      <div className="card-image">
                          <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" width="50" />
                      </div>
                      <div className="card-body">
                          <h3>U.S. Based Lending & Customer Service</h3>
                      </div>
                  </div>
              </div>
              <div className="col-md-2">
                  <div className="card justify-content-inherit">
                      <div className="card-image">
                          <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/calandar.png" className="icon" alt="120-180 Day Credit Commitment" width="50" />
                      </div>
                      <div className="card-body">
                          <h3>120-180 Day Credit Commitment</h3>
                      </div>
                    </div>
              </div>
              <div className="col-md-2">
                  <div className="card justify-content-inherit">
                      <div className="card-image">
                          <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" width="70" />
                      </div>
                      <div className="card-body">
                          <h3>FDIC<br/> Insured</h3>
                      </div>
                    </div>
              </div>
              <div className="col-md-2">
                  <div className="card justify-content-inherit">
                      <div className="card-image">
                          <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" width="70" />
                      </div>
                      <div className="card-body">
                          <h3>High Approval Rates</h3>
                      </div>
                    </div>
              </div>
          </div>
        </div>
        </>
        ) : (
          <div className="container">
          {node.content && (
            <div
              className="row justify-content-center"
              dangerouslySetInnerHTML={{ __html: node.content }}
            />
          )}

          <div className="benefit-option">
            <ul>
              {node.home_owner.benefitOption.map((item, i) => (
                <li key={item.fieldGroupName + i}>
                  <div className="box-circle icon regions-green content-center">
                    {(() =>
                      item?.optionIcon?.caption ? (
                        <FontAwesomeIcon
                          className="fa-icon"
                          icon={RemoveHTML(item?.optionIcon?.caption)}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={item.optionIcon.sourceUrl}
                          alt={item.optionIcon.altText}
                        />
                      ))()}
                    {/* <img src={item.optionIcon.sourceUrl} alt={item.optionIcon.altText} /> */}
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: item.optionTitle }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
        )}
      </section>
      {isStaging ? (
        null
        ) : (
      <section
        className="pt-3 pb-3 relative"
        style={{
          background:
            "url(" + node.home_owner.paymentBanner.sourceUrl + ") center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "515px",
        }}
      >
      </section>
      )}
      <section className={`section-gap ${isStaging ? "bg-light-gray" : null }`} id="howtopay">
        {isStaging ? (
            <div className="container">
                <div className="col-md-12 mb-5">
                    <h2 className="text-center mb-4">Six Ways to Make a Payment</h2>
                </div>
            <div className="row">
                <div className="col-md-12">
                <Tabbordion
                        blockElements={blockElements}
                        animateContent={"height"}
                        mode={"toggle"}
                        className="regions-accordion"
                        name="accordion" >
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make a payment online</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>If your account number starts with 456, <Link classname="eb-link" to="https://account.enerbank.com./">log in to your Regions Home Improvement Financing account here</Link>. <br/>
                                If your account number begins with numbers other than 456, <Link classname="eb-link" to="https://payments.enerbank.com/">log in to your Regions Home Improvement Financing account here</Link>.</li>
                                <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account.</li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                                <li><Link classname="eb-link" to="https://enerbank.com/text-to-pay-feature/">Learn more about text-to-pay</Link>.</li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Schedule recurring payments at no charge:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>          
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>Please contact your relationship manager or call{" "} <a href="tel:888.390.1220">888.390.1220</a>, option 1 or</li>
                                <li>Complete the <Link classname="eb-link" to="https://enerbank.com/static/automatic-debt-authorization-form-5eb34b7350b5addf0bb182521fdde02f.pdf">automatic debit form</Link> and return it to: <br/>
                                <strong>Regions|EnerBank USA <br/>
                                650 S Main St, Suite 1000<br/>
                                Salt Lake City, UT 84101</strong> </li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make an online bill payment through your financial institution:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <p>Set up Regions Bank as a new payee with your financial institution&#39;s online bill payment service. </p>
                              <p>Set up a payment with the payment amount due to Regions Bank. </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Mail your payment with a payment coupon:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>If your account number starts with 456:  <br/>
                                <strong>Regions|EnerBank USA <br/>
                                PO Box 30122 <br/>
                                Salt Lake City, UT 84130-0122 </strong> </li>
                                <li>If your account number begins with numbers other than 456: <br/>
                                <strong>Regions|EnerBank USA <br/>
                                PO Box 26856 <br/>
                                Salt Lake City, UT 84126-0856 </strong> </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Send a payment via overnight delivery:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                            <p><strong>Regions Home Improvement Financing<br/>
                              650 S Main St, Suite 1000 <br/>
                              Salt Lake City, UT 84101 </strong></p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make a payment over the phone: </span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>Call Customer Service at{" "} <a href="tel:888.390.1220">888.390.1220</a>, and choose option 1 </li>
                                <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account. </li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                    </Tabbordion>
                </div>
            </div>
            <div className="row justify-content-center align-items-top pt-5">
              <div className="col-md-12 text-center mb-3">
                <Button variant="" className="btn btn-green" 
                onClick={loginForm}>
                  Account Login</Button>
              </div>
              <div className="col-md-12 text-center">
                <Button variant="" className="btn btn-green" 
                onClick={() => DownloadFile(debitForm)}>
                  Automatic Debit Form (PDF)</Button>
              </div>
            </div>
          </div>
    ) : (
    <div className="container">
    <h2 className="mb-5 mb-md-3 text-center">
          <span>Six Ways to Make a Payment</span>
        </h2>
        <Tabbordion
          blockElements={blockElements}
          animateContent={"height"}
          mode={"toggle"}
          className="accordion loan_offer mx-4 mx-lg-5"
          name="accordion"
        >
          {node.accordion.tabpanel.map((item, i) => (
            <TabPanel key={item.fieldGroupName + i}>
              <TabLabel className="w-100 text-left btn btn-link">
                {item.tablabel}
              </TabLabel>
              <TabContent>
                <div dangerouslySetInnerHTML={{ __html: item.tabcontent }} />
              </TabContent>
            </TabPanel>
          ))}
        </Tabbordion>

        <div className="text-center mt-4">
          <button
            className="btn btn-primary f-bold equal-wd mb-4"
            onClick={loginForm}
          >
            Account Login
          </button>
          <br />
          <button
            className="btn btn-primary f-bold equal-wd mb-4"
            onClick={() => DownloadFile(debitForm)}
          >
            Automatic Debit Form (PDF)
          </button>
          <p>
            Question about a loan? Have a comment? We want to hear from you.
          </p>
          {/* feedback form */}
          {/* <button onClick={toggle} className="btn btn-primary f-bold equal-wd mb-4">Leave Feedback</button> */}
          <a
            href="/feedback/"
            className="btn btn-primary text-white f-bold equal-wd mb-4"
          >
            Leave Feedback
          </a>
        </div>
        <Collapse
          isOpen={collapse}
          // onEntering={onEntering}
          // onEntered={onEntered}
          // onExiting={onExiting}
          // onExited={onExited}
        >
          <form>
            <textarea
              value={value}
              className="form-control mb-3"
              name="leave_feedback"
              onChange={handleChange}
              style={{ height: "100px" }}
              placeholder="Leave feedback here ..."
            />
            <button className="btn btn-primary">Submit</button>
            <button className="btn btn-primary ml-3" onClick={toggle}>
              Cancel
            </button>
          </form>
        </Collapse>
        </div>
        )}
      </section>
      {/* {isStaging ? (
        <section className="rds-marquee bg-light-gray">
          <div className="">
            <h2 className="text-center mb-4">Log in to your account</h2>
          </div>
          <div className="container">
            <div className="text-center">
              <p className="e">Monitor your accounts, make payments.</p>
            </div>
            <div className="row justify-content-center align-items-top pt-5">
              <div className="col-md-12 text-center mb-5">
                <Button variant="" className="btn btn-gray" 
                onClick={loginForm}>
                  Account Login</Button>
              </div>
              <div className="col-md-12 text-center">
                <Button variant="" className="btn btn-gray" 
                onClick={() => DownloadFile(debitForm)}>
                  Automatic Debit Form (PDF)</Button>
              </div>
            </div>
          </div>
        </section>
        ) : (
        null
        )} */}

      {accLogin && (
        <Modal show={accLogin} animation={true} centered={true} size="md">
          <button
            type="button"
            className="close text-black"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeLoginForm}
            style={{ opacity: 1 }}
          >
            <img
              src={close_icon}
              width="22"
              alt="close icon"
              style={{ fill: "#4d4d4d" }}
            />
          </button>
          <div className="p-4">
            <h4 className="modal-title mb-4" id="modalTitle">
              Borrower Account Log In
            </h4>
            <div id="modalContent" className="small line-height-normal">
              <p style={{ lineHeight: "normal" }}>
                If your account number starts with 456 <br />
                <a
                  href="https://account.enerbank.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Here{" "}
                </a>
              </p>
              <p style={{ lineHeight: "normal" }}>
                Otherwise <br />
                <a
                  href="https://enerbankpayments.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
              <p className="thirdparty">
                <small>
                  Third party service providers host the online payment
                  platforms for Regions Bank/EnerBank USA, and they are not an
                  affiliate of Regions Bank/EnerBank USA. Use of the payment
                  platforms is subject to third party service providers' terms
                  of use and privacy policies which are provided at the payment
                  platforms. Customers should review them before using the
                  platforms.
                </small>
              </p>
              <p className="alert-txt">
                <small>
                  Please be advised that Regions Bank | EnerBank USA accepts
                  payments using a debit card but does NOT accept credit cards.
                </small>
              </p>
            </div>
          </div>
        </Modal>
      )}
      <ResendLoanDocsPopup open={open} onClose={() => setOpen(false)} />
    </Layout>
  )
}

export default HomeOwnerPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        top_banner {
          banner {
            backgroundImage {
              sourceUrl
              altText
            }
            bannerLinks {
              fieldGroupName
              links {
                title
                url
              }
            }
          }
        }

        home_owner {
          makePaymentWay
          paymentBanner {
            sourceUrl
            altText
          }
          benefitOption {
            fieldGroupName
            optionTitle
            optionIcon {
              id
              sourceUrl
              altText
              title
              caption
            }
          }
        }

        accordion {
          tabpanel {
            fieldGroupName
            tabcontent
            tablabel
          }
        }

        video_section {
          video {
            videoUrl
            videoBanner {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
